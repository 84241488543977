<template>
    <div class="vertFlex">
        <div class="topbar">
            <img alt="school logo" :src="badgeIcon"  class="logo" @click="refresh()" >
            <div class="">
                <label class="menutitleText">{{toplabel}}</label>
            </div>
            <!-- <img alt="menu" src="@/assets/menu.svg"  class="menuimg" @click="toggleMenu()"> -->
            <div class="menuicondiv">
                <i class="material-icons menuimg" @click="toggleMenu()" v-if="loggedIn">menu</i>
            </div>
            
        </div>
        <div v-if="showMenu" class="menudiv">
            <div>
                <a id="location" @click="home()" >
                    <span class="item" >Home</span>
                </a>
            </div>
            <div>
                <a id="location" @click="refresh()" >
                    <span class="item" >Refresh</span>
                </a>
            </div>
            <div>
                <a  @click="logout()" >
                    <span class="item" >Logout</span>
                </a>
            </div>
            <div v-if="BadgeDesign.showTimeclock">
                <a  @click="timeclock()" >
                    <span class="item" >TimeClock</span>
                </a>
            </div>
            <div v-if="showDocument">
                <a  @click="documents()" >
                    <span class="item" >Documents</span>
                </a>
            </div>
            <div v-if="showPayAccount">
                <a  @click="payAccount()" >
                    <span class="item" >Pay Account</span>
                </a>
            </div>
            <div v-for="menuitem in badgeData.MenuItemList" :key="menuitem.id" :value="menuitem.id" >
                <a id="location" :href="menuitem.websiteUrl" target="_blank">
                    <span class="item">{{menuitem.name}}</span>
                </a>
            </div>

        </div>
    </div>
    
</template>

<script>
import {mapFields} from 'vuex-map-fields'
export default {
    name:'badgeburgermenu',
    components:{
    },
    props:{
        toplabel:{
            type:String, 
        },
    },
    computed:{
        ...mapFields([
            'badgeData',
            'imageBasePath',
            'BadgeDesign',
            'loggedIn'
        ]),
        badgeIcon(){
            if(this.BadgeDesign==undefined){return './../' + window.SCHOOL_LOGO}
            return this.imageBasePath + this.BadgeDesign.badgeIcon
        },
        showDocument(){
            var oktoshow = true;
            if(window.SHOW_DOCUMENT_IN_MENU!=undefined){
                oktoshow=window.SHOW_DOCUMENT_IN_MENU=="True"
            }
            return this.BadgeDesign.showDocuments && oktoshow
        }
    },
    methods:{
        toggleMenu(){
            this.showMenu=!this.showMenu
        },
        home(){
            this.showMenu=false
            this.$emit('home')
        },
        refresh(){
            this.showMenu=false
            this.$emit('refresh')
            
        },
        timeclock(){
            this.showMenu=false
            this.$emit('time')
            
        },
        documents(){
            this.showMenu=false
            this.$emit('documents')
        },
        payAccount(){
            this.showMenu=false
            this.$emit('payaccount')
        },
        logout(){
            this.showMenu=false
            this.$emit('logout');
        }
    },
    data(){
        return{
            showMenu:false,
            schoolLogoFilePath:'./../' + window.SCHOOL_LOGO,
            showPayAccount:window.MENU_SHOW_PAY_ACCOUNT,
            
        }
    }
}
</script>

<style>
.topbar{
    display:grid;
    grid-template-columns:1fr 1fr 1fr;

}
.menutitleText{
    font-weight: bold;
    color:var(--title-text-color);
 
}
.menudiv{
    margin:0px;
    padding:0px;
    text-align:right;
}
.menuicondiv{
    text-align: right;
}
.menuimg{
    
    margin:0px;
    color:var(--title-text-color);
    font-size:xx-large;
}
.menuimg:hover{
    cursor:pointer;
}
.item{
    color:var(--menu-text-color);
    text-decoration-color: var(--menu-text-color);
    text-decoration: underline;
}
.item:hover{
    color:var(--menu-text-color);
    cursor:pointer;
}
.item:visited{
    color:var(--menu-text-color);
    cursor:pointer;
}
</style>
